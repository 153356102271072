<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Kode Klasifikasi</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.kode"
                  name="kode"
                  type="text"
                  label="Kode Klasifikasi"
                  placeholder="Kode Klasifikasi"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nama"
                  name="nama"
                  type="text"
                  label="Nama Klasifikasi"
                  placeholder="Nama Klasifikasi"
                />
              </div>
            </div>
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Submit"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      @action="onDelete(deleteData)"
    />
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Modal from "@/components/modal/modal.vue";

export default {
  components: {
    Input,
    Multiselect,
    RotateSquare5,
    Modal,
  },
  computed: {
    isInvalidUnitKerja() {
      return (
        this.dropdownUnitKerja.isTouched &&
        this.dropdownUnitKerja.value.length === 0
      );
    },
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.kodeKlasifikasi;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    filteredUkerList() {
      let list = this.dropdownUnitKerja.ukerList;
      return list;
    },
  },
  data() {
    // const uploader = this.fineUploader(this, "template_surat");

    return {
      // uploader,
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        // kode: "",
        // is_superior: "",
        kode: "",
        nama: "",
      },
      dropdown: {
        parentList: [],
        isinvalidInduk: false,
      },
      dropdownUnitKerja: {
        isTouched: false,
        ukerList: [],
        value: [],
        isLoadingUnitKerja: false,
      },
      isDisabled: false,
      isLoading: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  async mounted() {
    var query = "";
    this.parentFind(query);
    this.initialize();
    this.unitKerja(query);
  },

  methods: {
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "submit") {
        if (option.data == 0) {
          this.modalTitle = "Simpan Data?";
          this.modalContent = "<p>Apakah anda yakin ingin menyimpan surat?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        } else if (option.data == 1) {
          this.modalTitle = "Kirim Data?";
          this.modalContent = "<p>Apakah anda yakin ingin mengirim surat?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        }
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    onChangeUnitKerja(value) {
      this.dropdownUnitKerja.value = value;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/kodeKlasifikasi/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("kodeKlasifikasi/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("kodeKlasifikasi/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        // name: data.name,
        // kode: data.kode,
        // is_superior: data.is_superior,
        kode: data.kode,
        nama: data.nama_klasifikasi,
      };
      this.dropdownUnitKerja.value = data.unitPos;
    },
    unitKerja(query) {
      const mv = this;
      mv.dropdownUnitKerja.isLoadingUnitKerja = true;
      let address = "";
      // if(mv.payload.tuk.value == 3){
      //   address = `kategoriAsalSurat/listByLevel?s=${query ? query : ""}`
      // }else{
      address = `clasificationdok/list?s=${query ? query : ""}`;
      // }
      axios
        .get(address)
        .then((res) => {
          mv.dropdownUnitKerja.ukerList = res.data.items;
          mv.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    closeModal() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    onChangeParent(value) {
      this.payload.parent = value;
    },
    parentFind(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.dropdown.parentList = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("kodeKlasifikasi/onCancel");
    },
    submit() {
      let _this = this;
      let that = _this;
      const payload = {
        kode: this.payload.kode,
        nama: this.payload.nama,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("kodeKlasifikasi/submitEdit", {
              // payload,
              kode: this.payload.kode,
              nama: this.payload.nama,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("kodeKlasifikasi/submitAdd", payload);
          }
        } else {
          this.isVisible = false;
          window.scrollTo(0, 0);
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

